import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'onClick'
        }
    }

    init() {

    }

    onClick(e) {
        e.preventDefault();
        this.call('scrollTo',e.currentTarget.getAttribute('href'), 'Scroll', 'main');
    }
}
