import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

    }

    init() {

        this.splitTimeout = setTimeout(() => {
            const splittedTitle = new SplitText(this.el, {type:this.getData('type') !== null ? this.getData('type') : 'lines'});

        }, 1000);

    }

    destroy() {
        super.destroy();
        clearTimeout(this.splitTimeout);
    }

}
