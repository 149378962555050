import { module } from 'modujs';
import { transform } from '../utils/transform';
import { lerp } from '../utils/maths';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {        
        if(window.isMobile) return;

        this.mouse = {
            x: 0,
            y: 0,
            lerpedX: 0,
            lerpedY: 0
        }

        this.hasMoved = false;

        this.bindMousemove = this.mousemove.bind(this);
        window.addEventListener('mousemove',this.bindMousemove);
    }


    mousemove(e) {
        if(!this.hasMoved) {
            this.hasMoved = true;
            this.el.classList.add('has-moved');
            this.animate();
        }

        this.mouse.x = (e.clientX / window.innerWidth) - 0.5;
        this.mouse.y = (e.clientY / window.innerHeight) - 0.5;

    }

    animate() {
        this.raf = requestAnimationFrame(() => this.animate());
        
        this.mouse.lerpedX = lerp(this.mouse.lerpedX, this.mouse.x, 0.05);
        this.mouse.lerpedY = lerp(this.mouse.lerpedY, this.mouse.y, 0.05);

        transform(this.el,`rotateY(${this.mouse.lerpedX * 5}deg) rotateX(${-this.mouse.lerpedY * 5}deg)`)
    }

    destroy() {
        if(window.isMobile) return;

        window.removeEventListener('mousemove',this.bindmousemove);
        cancelAnimationFrame(this.raf)
    }
}
