import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: 'trigger'
        }
    }

    init() {

    }

    trigger(e) {

        if(this.el.getAttribute('data-provider') !== null) {
            this.call('openVideo',
                {
                    provider: this.el.getAttribute('data-provider'),
                    id: this.el.getAttribute('data-id'),
                    iframe: this.el.getAttribute('data-iframe')
                },'Popup')
        } else {
            this.call('openCarousel',
            {
                id: this.el.getAttribute('data-id')
            },'Popup')
        }
    }
}