import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        if(window.isMobile || !window.hasUserGesture) {
            return;
        }

        this.index = 0;
        this.$items = this.$('item');
        this.$points = this.$('point');
        
        setTimeout(() => {
            this.change();
            this.el.classList.add('is-launched');
        }, 2000)
        
    }

    change() {
        this.el.classList.remove('is-active');

        for (let i = 0; i < this.$items.length; i++) {
            const item = this.$items[i];
            const point = this.$points[i];

            if(item.classList.contains('is-active')) {
                item.classList.remove('is-active');
                point.classList.remove('is-active');
            }

            if(i == this.index) {
                item.classList.add('is-active');
                point.classList.add('is-active');
            }
        }

        if(this.index <= this.$items.length - 1) {
            this.index++;
        } else {
            this.el.classList.add('is-end');
            html.classList.add('has-tutorial-finished');
            return;
        }

        setTimeout(() => {
            this.el.classList.add('is-active');
        },100);

        setTimeout(() => {
            this.change();
        },5100);

    }
    
}
