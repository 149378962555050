import modular from 'modujs';
import * as modules from './modules';
import globals from './globals';
import { html } from './utils/environment';

const app = new modular({
    modules: modules
});

window.hasUserGesture = false;

window.isMobile = (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

if(window.isMobile) {
    html.classList.add('is-mobile');
}

window.onload = (event) => {
    const $style = document.getElementById("stylesheet");

    if ($style.isLoaded) {
        init();
    } else {
        $style.addEventListener('load', (event) => {
            init();
        });
    }
};

function init() {
    app.init(app);
    globals();

    html.classList.add('is-loaded');
    html.classList.add('is-ready');
    html.classList.remove('is-loading');

    setTimeout(() => {
        html.classList.add('is-first-load');
        html.classList.add('has-dom-ready');
    }, 600)
}

