import { module } from 'modujs';
import Macy from 'macy';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.instance = Macy({
            container: this.el,
            columns: 3,
            trueOrder: true,
            breakAt: {
                700: 1
            }
        })

        this.refreshScroll = (ctx) => {
            // console.log('refreshScroll', ctx);
            requestAnimationFrame(() => {
                // console.log('updateScroll');
                this.call('update', null, 'Scroll')
            })
        }
        this.refreshScroll();

        this.instance.on(this.instance.constants.EVENT_INITIALIZED, this.refreshScroll)
        this.instance.on(this.instance.constants.EVENT_RESIZE, this.refreshScroll)
    }

    destroy() {
        this.instance.remove()
        this.instance = null
    }
}
