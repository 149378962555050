import { module } from 'modujs';

/**
* Overwriting the UnityLoader to fix a bug in their regular expression for Mac OS X 11+
*/


export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        if(window.isMobile || !window.hasUserGesture) {
            window.location.href = '/';
        } else {
            this.unityInstance = UnityLoader.instantiate(this.el, 'https://d2m279d3o3kbzp.cloudfront.net/v6-seoul-2/Build/Seoul2.json', {onProgress: UnityProgress});
        }

    }

    launch() {

    }

    setFullscreen() {
        this.unityInstance.SetFullscreen(1);
    }

    destroy() {

    }
}
