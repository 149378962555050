import { module } from 'modujs';
import modularLoad from '../utils/load';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {

        this.analyticsId = this.getData('analytics');

        this.load = new modularLoad({
            enterDelay: 800,
            transitionsHistory: false
        });

        this.load.on('loading', (transition, oldContainer) => {
            html.classList.remove('has-dom-ready');
        });

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            html.classList.remove('has-scrolled');
            window.hasUserGesture = true;

            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');

            setTimeout(() => {
                html.classList.add('has-dom-ready');
            }, 300)

            if(window.gtag && this.analyticsId != null) {
                gtag('config', this.analyticsId, {
                    'page_path':  location.pathname,
                    'page_title': document.title
                });
            }
        });
    }

    goto(url) {
        this.load.goTo(url);
    }
}
