import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                prev: 'prev',
                next: 'next'
            }
        }
    }

    init() {

        this.$controls = this.$('controls')[0];

        this.videosSwiper = new Swiper(this.$('videos')[0], {
            loop: true,
            slidesPerView: 1,
            speed: 600,
            parallax:true,
            on: {
                init: () => {
                    setTimeout(() => {
                        this.call('update', this.$('videos')[0], 'app')
                    },1000);
                }
            }
        });

        // this.titlesSwiper = new Swiper(this.$('titles')[0], {
        //     loop: true,
        //     slidesPerView: 1,
        //     speed: 600,
        //     parallax:true
        // });

        this.videosSwiper.controller.control = this.titlesSwiper;
        // this.titlesSwiper.controller.control = this.videosSwiper;

    }

    prev() {
        this.videosSwiper.slidePrev();
    }

    next() {
        this.videosSwiper.slideNext();
    }
}